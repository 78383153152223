<template>
  <el-card shadow="never">
    <div class="container">
      <h2>{{ $t('incoming_company_title') }}</h2>
      <el-divider />
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        label-position="top"
        style="width: 900px; margin: 0 auto"
      >
        <!-- 2 其他国家地区展示内容 -->
        <template v-if="curArea === 2">
          <el-form-item :label="$t('incoming_company_form3')" prop="country">
            <el-select
              v-model="form.country"
              :placeholder="$t('incoming_company_select_placeholder')"
              style="width: 100%"
              filterable
            >
              <el-option
                v-for="item in countryList"
                :label="item.name"
                :value="item.code"
                :key="item.code"
              />
            </el-select>
          </el-form-item>
        </template>
        <!-- 0 大陆企业 和 2 其他国家地区 展示内容 -->
        <template v-if="[0, 2].includes(curArea)">
          <el-form-item
            :label="
              curArea === 0
                ? $t('incoming_company_form1')
                : $t('incoming_company_form2')
            "
            prop="licenseUrl"
          >
            <upload
              :contentWriting="
                curArea === 0
                  ? $t('incoming_company_upload_tip1')
                  : $t('incoming_company_upload_tip2')
              "
              :serverImageUrl="form.licenseUrl"
              @upload-success="path => handleUploadSuccess(path, 'licenseUrl')"
            />
            <div class="el-upload__tip">
              {{ $t('incoming_company_upload_tip3') }}
            </div>
          </el-form-item>
        </template>
        <!-- 1 香港企业展示内容 -->
        <template v-if="curArea === 1">
          <el-form-item :label="$t('incoming_company_form4')" prop="licenseUrl">
            <div style="display: flex">
              <upload
                :content-writing="$t('incoming_company_upload_tip4')"
                :server-image-url="form.registrationUrl"
                @upload-success="
                  path => handleUploadSuccess(path, 'registrationUrl')
                "
                style="margin-right: 20px"
              />
              <upload
                :content-writing="$t('incoming_company_upload_tip5')"
                :server-image-url="form.licenseUrl"
                @upload-success="
                  path => handleUploadSuccess(path, 'licenseUrl')
                "
              />
            </div>
          </el-form-item>
        </template>
        <el-form-item :label="$t('incoming_company_form5')" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item :label="$t('incoming_company_form6')" prop="engName">
          <el-input v-model="form.engName" />
        </el-form-item>
        <!-- 0 大陆企业展示内容 -->
        <template v-if="curArea === 0">
          <el-form-item
            :label="$t('incoming_company_form7')"
            prop="companyCode"
          >
            <el-input v-model="form.companyCode" />
          </el-form-item>
          <el-form-item :label="$t('incoming_company_form8')" prop="address">
            <el-input v-model="form.address" />
          </el-form-item>
          <el-form-item
            :label="$t('incoming_company_form9')"
            prop="operateAddress"
          >
            <el-input v-model="form.operateAddress" />
          </el-form-item>
          <el-form-item
            :label="$t('incoming_company_form10')"
            prop="foundedTime"
          >
            <el-date-picker
              v-model="form.foundedTime"
              type="date"
              :placeholder="$t('incoming_company_select_placeholder')"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item
            :label="$t('incoming_company_form11')"
            prop="capitalNumber"
          >
            <el-input
              :placeholder="$t('incoming_company_input_placeholder')"
              v-model="form.capitalNumber"
              onkeyup="value=value.replace(/[^\d]/g,'')"
            >
              <el-select
                style="width: 160px"
                v-model="form.currency"
                slot="append"
                :placeholder="$t('incoming_company_select_placeholder')"
              >
                <el-option
                  v-for="item in capitalList"
                  :label="item.value"
                  :value="item.name"
                  :key="item.value"
                />
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('incoming_company_form12')" prop="date">
            <el-date-picker
              v-model="form.date"
              type="daterange"
              :range-separator="$t('incoming_company_date_placeholder1')"
              :start-placeholder="$t('incoming_company_date_placeholder2')"
              :end-placeholder="$t('incoming_company_date_placeholder3')"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item :label="$t('incoming_company_form13')" prop="nature">
            <el-select
              v-model="form.nature"
              :placeholder="$t('incoming_company_select_placeholder')"
              style="width: 100%"
            >
              <el-option
                v-for="item in natureList"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              />
            </el-select>
          </el-form-item>
        </template>
        <!-- 1 香港企业展示内容 -->
        <template v-if="curArea === 1">
          <el-form-item
            :label="$t('incoming_company_form14')"
            prop="registrationCode"
          >
            <el-input v-model="form.registrationCode" />
          </el-form-item>
          <el-form-item
            :label="$t('incoming_company_form15')"
            prop="companyCode"
          >
            <el-input v-model="form.companyCode" />
          </el-form-item>
          <el-form-item :label="$t('incoming_company_form16')" prop="date">
            <el-date-picker
              v-model="form.date"
              type="daterange"
              :range-separator="$t('incoming_company_date_placeholder1')"
              :start-placeholder="$t('incoming_company_date_placeholder2')"
              :end-placeholder="$t('incoming_company_date_placeholder3')"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item :label="$t('incoming_company_form17')" prop="address">
            <el-input v-model="form.address" />
          </el-form-item>
          <el-form-item
            :label="$t('incoming_company_form18')"
            prop="operateAddress"
          >
            <el-input v-model="form.operateAddress" />
          </el-form-item>
        </template>
        <!-- 2 其他国家地区展示内容 -->
        <template v-if="curArea === 2">
          <el-form-item
            :label="$t('incoming_company_form19')"
            prop="companyCode"
          >
            <el-input v-model="form.companyCode" />
          </el-form-item>
          <el-form-item :label="$t('incoming_company_form20')" prop="date">
            <el-date-picker
              v-model="form.date"
              type="daterange"
              :range-separator="$t('incoming_company_date_placeholder1')"
              :start-placeholder="$t('incoming_company_date_placeholder2')"
              :end-placeholder="$t('incoming_company_date_placeholder3')"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item :label="$t('incoming_company_form21')" prop="address">
            <el-input v-model="form.address" />
          </el-form-item>
          <el-form-item
            :label="$t('incoming_company_form22')"
            prop="operateAddress"
          >
            <el-input v-model="form.operateAddress" />
          </el-form-item>
        </template>

        <el-form-item
          :label="$t('incoming_company_form23')"
          prop="businessScope"
        >
          <el-input v-model="form.businessScope" />
        </el-form-item>
        <el-form-item :label="$t('incoming_company_form24')" prop="website">
          <el-input v-model="form.website" />
        </el-form-item>
        <el-form-item
          :label="$t('incoming_company_form25')"
          prop="equityStructureUrl"
        >
          <upload
            :contentWriting="$t('incoming_company_form25')"
            :serverImageUrl="form.equityStructureUrl"
            @upload-success="
              path => handleUploadSuccess(path, 'equityStructureUrl')
            "
          />
          <div class="el-upload__tip">
            {{ $t('incoming_company_upload_tip3') }}
          </div>
        </el-form-item>
        <!-- 1 香港企业展示内容 -->
        <template v-if="curArea === 1">
          <el-form-item :label="$t('incoming_company_form26')" prop="reportUrl">
            <el-upload
              :action="this.fileUrl"
              :headers="uploadHeader"
              :limit="1"
              :before-upload="beforeUpload"
              :on-success="response => handleSuccess(response, 'reportUrl')"
            >
              <el-button size="small" type="primary">
                {{ $t('incoming_company_upload1') }}
              </el-button>
              <div slot="tip" class="el-upload__tip">
                {{ $t('incoming_company_upload_tip6') }}
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('incoming_company_form27')" prop="groupUrl">
            <el-upload
              :action="this.fileUrl"
              :headers="uploadHeader"
              :before-upload="beforeUpload"
              :limit="1"
              :on-success="response => handleSuccess(response, 'groupUrl')"
            >
              <el-button size="small" type="primary">
                {{ $t('incoming_company_upload1') }}
              </el-button>
              <div slot="tip" class="el-upload__tip">
                {{ $t('incoming_company_upload_tip6') }}
              </div>
            </el-upload>
          </el-form-item>
        </template>
      </el-form>
    </div>
  </el-card>
</template>

<script>
import { getArea, getConfigList, getSelectList } from '../api';
import storage from '@/utils/storage';
import Upload from './upload';
import config from '@/config';
import { commonCheck } from '@/utils/utils';

export default {
  props: {
    // 0 大陆  1 香港  2 其他企业
    curArea: {
      type: Number,
      default: 0,
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    curArea: {
      handler() {
        if (this.$refs.form) {
          // this.$refs.form.resetFields();
          // Object.keys(this.form).forEach(key => (this.form[key] = ''));
          this.form = { ...this.form };
        }
      },
    },
    detail: {
      handler(newVal) {
        if (newVal && Object.keys(newVal).length) {
          Object.keys(this.form).forEach(key => {
            this.form[key] = newVal[key];
          });
          this.form.date = [
            newVal.certificateStartTime,
            newVal.certificateEndTime,
          ];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    rules() {
      return {
        name: [
          {
            validator: commonCheck,
            required: true,
            trigger: 'blur',
          },
        ],
        operateAddress: [
          {
            validator: commonCheck,
            required: true,
            trigger: 'blur',
          },
        ],
        engName: [
          {
            validator: commonCheck,
            required: true,
            trigger: 'blur',
          },
        ],
        companyCode: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        registrationCode: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        address: [
          {
            validator: commonCheck,
            required: true,
            trigger: 'blur',
          },
        ],
        foundedTime: [
          {
            required: true,
            message: this.$t('COMMON_ERR4'),
            trigger: 'change',
          },
        ],
        capitalNumber: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        date: [
          {
            required: true,
            message: this.$t('COMMON_ERR4'),
            trigger: 'change',
          },
        ],
        nature: [
          {
            required: true,
            message: this.$t('COMMON_ERR4'),
            trigger: 'change',
          },
        ],
        businessScope: [
          {
            validator: commonCheck,
            required: true,
            trigger: 'blur',
          },
        ],
        website: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        licenseUrl: [
          {
            required: true,
            message: this.$t('COMMON_ERR3'),
            trigger: 'change',
          },
        ],
        reportUrl: [
          {
            required: true,
            message: this.$t('COMMON_ERR3'),
            trigger: 'change',
          },
        ],
        groupUrl: [
          {
            required: true,
            message: this.$t('COMMON_ERR3'),
            trigger: 'change',
          },
        ],
        equityStructureUrl: [
          {
            required: true,
            message: this.$t('COMMON_ERR3'),
            trigger: 'change',
          },
        ],
        country: [
          {
            required: true,
            message: this.$t('COMMON_ERR3'),
            trigger: 'change',
          },
        ],
      };
    },
  },
  data() {
    return {
      form: {
        engName: '',
        name: '',
        companyCode: '',
        address: '',
        capital: '',
        foundedTime: '',
        capitalNumber: '',
        currency: '',
        nature: '',
        businessScope: '',
        licenseUrl: '',
        registrationCode: '',
        registrationUrl: '',
        operateAddress: '',
        country: '',
        reportUrl: '',
        groupUrl: '',
        date: '',
        website: '',
        equityStructureUrl: '',
      },
      capitalList: [],
      natureList: [],
      countryList: [],
      uploadHeader: { Authorization: storage.getItem('token') },
      licenseImg: '',
    };
  },
  created() {
    this.fileUrl = `${config.baseApi}/file-api/file-server/saas/add`;
  },
  mounted() {
    this.getNatureList();
    this._getConfigList();
    this._getArea();
  },
  methods: {
    async _getArea() {
      this.countryList = await getArea();
    },
    async _getConfigList() {
      this.capitalList = await getConfigList(1);
      this.form.currency = this.capitalList[0].value;
    },
    async getNatureList() {
      try {
        this.natureList = await getSelectList({ kind: 4 });
      } catch (e) {
        console.error(e);
      }
    },
    async submitForm(type) {
      if (type === 2) {
        const submitData = { ...this.form };
        submitData.certificateStartTime =
          submitData.date && submitData?.date?.[0];
        submitData.certificateEndTime =
          submitData.date && submitData?.date?.[1];
        submitData.objType = this.curArea;
        delete submitData.date;
        return { ...submitData };
      }
      const valid = await this.$refs.form.validate();
      if (valid) {
        const submitData = { ...this.form };
        submitData.certificateStartTime =
          submitData.date && submitData?.date?.[0];
        submitData.certificateEndTime =
          submitData.date && submitData?.date?.[1];
        submitData.objType = this.curArea;
        delete submitData.date;
        const res = {};
        for (const key in submitData) {
          if (submitData[key] !== '') {
            res[key] = submitData[key];
          }
        }
        return { ...res };
      } else {
        return null;
      }
    },
    handleUploadSuccess(path, key) {
      this.form[key] = path;
    },
    handleSuccess(response, key) {
      if (key === 'reportUrl') {
        this.form.reportUrl = response.dataSet.path;
      } else if (key === 'groupUrl') {
        this.form.groupUrl = response.dataSet.path;
      }
    },
    /**
     * 文件上传之前判断类型是否正确
     */
    beforeUpload(file) {
      const fileName = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['pdf', 'jpg', 'jpeg', 'png'];
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (whiteList.indexOf(fileName) === -1) {
        this.$message.warning('只能上传 图片/pdf 文件');
        return false;
      }
      if (!isLt2M) {
        this.$message.warning('上传文件不能超过15MB');
        return false;
      }
    },
  },
  components: { Upload },
};
</script>

<style scoped lang="scss">
.container {
  width: 80%;
  margin: 0 auto;
  padding-top: 30px;

  h2 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    text-indent: 1em;
    font-weight: bold;
  }
  .upload-tip {
    margin-top: 20px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 22px;
  }
}

::v-deep.el-divider--horizontal {
  margin: 12px 0;
}

::v-deep.el-upload-dragger {
  width: 270px;
}

::v-deep.el-upload__tip {
  font-size: 13px;
  margin-top: 0;
}
</style>
