<template>
  <el-card shadow="never">
    <div class="container">
      <h2>{{ $t('incoming_agent_title') }}</h2>
      <el-divider />
      <el-form
        ref="form"
        :model="form"
        label-width="120px"
        label-position="top"
        style="width: 550px; margin: 0 auto"
      >
        <el-form-item :label="$t('incoming_agent_form1')">
          <el-radio-group v-model="proxyPerson">
            <el-radio :label="true">{{ $t('incoming_agent_form2') }}</el-radio>
            <el-radio :label="false">{{ $t('incoming_agent_form3') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="proxyPerson">
          <el-form-item :label="$t('incoming_agent_form4')">
            <el-radio-group v-model="form.identityType">
              <el-radio :label="1">
                {{ $t('incoming_director_form2') }}
              </el-radio>
              <el-radio :label="2">
                {{ $t('incoming_beneficiary_form8') }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <template v-if="form.identityType === 1">
            <el-form-item
              :label="$t('incoming_agent_form5')"
              prop="identityUrl"
            >
              <div style="display: flex">
                <upload
                  :content-writing="$t('incoming_director_form7')"
                  :serverImageUrl="form.identityUrl"
                  @upload-success="
                    path => handleUploadSuccess(path, 'identityUrl')
                  "
                  style="margin-right: 20px"
                />
                <upload
                  :content-writing="$t('incoming_director_form8')"
                  :serverImageUrl="form.identityBackUrl"
                  style="margin-right: 20px"
                  @upload-success="
                    path => handleUploadSuccess(path, 'identityBackUrl')
                  "
                />
                <upload
                  :content-writing="$t('incoming_agent_form6')"
                  :serverImageUrl="form.otherImageUrl"
                  @upload-success="
                    path => handleUploadSuccess(path, 'otherImageUrl')
                  "
                />
              </div>
            </el-form-item>
            <el-form-item
              :label="$t('incoming_agent_form7')"
              prop="name"
              :rules="{
                validator: commonCheck,
                trigger: 'blur',
              }"
            >
              <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item
              :label="$t('incoming_agent_form8')"
              prop="engName"
              :rules="{
                validator: commonCheck,
                trigger: 'blur',
              }"
            >
              <el-input v-model="form.engName" />
            </el-form-item>
            <el-form-item
              :label="$t('incoming_director_form15')"
              prop="identityNumber"
            >
              <el-input v-model="form.identityNumber" />
            </el-form-item>
            <el-form-item :label="$t('incoming_director_form16')" prop="sex">
              <el-select
                v-model="form.sex"
                :placeholder="$t('incoming_company_select_placeholder')"
                filterable
                style="width: 100%"
              >
                <el-option :label="$t('incoming_director_form17')" :value="0" />
                <el-option :label="$t('incoming_director_form18')" :value="1" />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('incoming_director_form19')"
              prop="birthday"
            >
              <el-date-picker
                v-model="form.birthday"
                type="date"
                :placeholder="$t('incoming_company_select_placeholder')"
                style="width: 100%"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
            <el-form-item :label="$t('incoming_director_form20')" prop="date">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                :range-separator="$t('incoming_company_date_placeholder1')"
                :start-placeholder="$t('incoming_company_date_placeholder2')"
                :end-placeholder="$t('incoming_company_date_placeholder3')"
                style="width: 100%"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :disabled="form.longTerm"
              />
              <el-radio-group
                v-model="form.longTerm"
                @change="handleRadioChange"
              >
                <el-radio :label="false">
                  {{ $t('incoming_director_form21') }}
                </el-radio>
                <el-radio :label="true">
                  {{ $t('incoming_director_form22') }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              :label="$t('incoming_agent_form9')"
              prop="address"
              :rules="{
                validator: commonCheck,
                trigger: 'blur',
              }"
            >
              <el-input v-model="form.address" />
            </el-form-item>
            <el-form-item
              :label="$t('incoming_agent_form10')"
              prop="otherFileUrl"
            >
              <el-upload
                :action="this.fileUrl"
                :headers="uploadHeader"
                :before-upload="beforeUpload"
                :limit="1"
                :on-success="
                  response => handleSuccess(response, 'otherFileUrl')
                "
              >
                <el-button size="small" type="primary">
                  {{ $t('incoming_company_upload1') }}
                </el-button>
                <div slot="tip" class="el-upload__tip">
                  {{ $t('incoming_company_upload_tip6') }}
                </div>
              </el-upload>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item :label="$t('incoming_director_form9')">
              <el-select
                v-model="form.country"
                :placeholder="$t('incoming_company_select_placeholder')"
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in countryList"
                  :label="item.name"
                  :value="item.code"
                  :key="item.code"
                />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('incoming_agent_form5')">
              <upload
                :content-writing="$t('incoming_director_form10')"
                :serverImageUrl="form.otherUrl"
                @upload-success="path => handleUploadSuccess(path, 'otherUrl')"
              />
            </el-form-item>
            <el-form-item
              :label="$t('incoming_agent_form7')"
              :rules="{
                validator: commonCheck,
                trigger: 'blur',
              }"
            >
              <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item
              :label="$t('incoming_agent_form8')"
              :rules="{
                validator: commonCheck,
                trigger: 'blur',
              }"
            >
              <el-input v-model="form.engName" />
            </el-form-item>
            <el-form-item :label="$t('incoming_director_form15')">
              <el-input v-model="form.identityNumber" />
            </el-form-item>
            <el-form-item :label="$t('incoming_director_form20')">
              <el-date-picker
                v-model="form['date']"
                type="daterange"
                :range-separator="$t('incoming_company_date_placeholder1')"
                :start-placeholder="$t('incoming_company_date_placeholder2')"
                :end-placeholder="$t('incoming_company_date_placeholder3')"
                style="width: 100%"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
            <el-form-item :label="$t('incoming_director_form16')">
              <el-select
                v-model="form.sex"
                :placeholder="$t('incoming_company_select_placeholder')"
                filterable
                style="width: 100%"
              >
                <el-option :label="$t('incoming_director_form17')" :value="0" />
                <el-option :label="$t('incoming_director_form18')" :value="1" />
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('incoming_director_form19')">
              <el-date-picker
                v-model="form.birthday"
                type="date"
                :placeholder="$t('incoming_company_select_placeholder')"
                style="width: 100%"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
            <el-form-item
              :label="$t('incoming_director_form24')"
              :rules="{
                validator: commonCheck,
                trigger: 'blur',
              }"
            >
              <el-input v-model="form.address" />
            </el-form-item>
            <el-form-item :label="$t('incoming_director_form25')">
              <upload
                :contentWriting="$t('incoming_director_form25')"
                :serverImageUrl="form.addressProfileUrl"
                @upload-success="
                  path => handleUploadSuccess(path, 'addressProfileUrl')
                "
              />
            </el-form-item>
          </template>
        </template>
      </el-form>
    </div>
  </el-card>
</template>

<script>
import { getArea } from '../api';
import storage from '@/utils/storage';
import Upload from './upload';
import config from '@/config';
import { commonReg } from '@/utils/utils';

export default {
  props: {
    // 0 大陆  1 香港  2 其他企业
    curArea: {
      type: Number,
      default: 1,
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    curArea: {
      handler() {
        if (this.$refs.form) {
          this.$refs.form.resetFields();
          Object.keys(this.form).forEach(key => (this.form[key] = ''));
        }
      },
    },
    detail: {
      handler(newVal) {
        if (newVal && Object.keys(newVal).length) {
          Object.keys(this.form).forEach(key => {
            this.form[key] = newVal[key];
          });
          const { beginDate, endDate } = newVal;
          if (beginDate && endDate) {
            this.form.date = [beginDate, endDate];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.fileUrl = `${config.baseApi}/file-api/file-server/saas/add`;
  },
  mounted() {
    this._getArea();
  },
  data() {
    const commonCheck = (rule, value, callback) => {
      if (value === '') {
        callback();
      } else {
        if (!commonReg.test(value)) {
          return callback(new Error(this.$t('COMMON_ERR2')));
        }
      }
    };
    return {
      proxyPerson: true,
      form: {
        identityType: 1,
        identityUrl: '',
        identityBackUrl: '',
        otherUrl: '',
        otherFileUrl: '',
        otherImageUrl: '',
        country: '',
        name: '',
        engName: '',
        identityNumber: '',
        sex: '',
        birthday: '',
        longTerm: false,
        address: '',
        date: '',
      },
      countryList: [],
      uploadHeader: { Authorization: storage.getItem('token') },
      commonCheck,
    };
  },
  computed: {
    rules() {
      const commonCheck = (rule, value, callback) => {
        if (value === '') {
          callback();
        } else {
          if (!commonReg.test(value)) {
            return callback(new Error(this.$t('COMMON_ERR2')));
          }
        }
      };
      return {
        name: [
          {
            validator: commonCheck,
            trigger: 'blur',
          },
        ],
        engName: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        address: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        identityNumber: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        identityUrl: [
          {
            required: true,
            message: this.$t('COMMON_ERR3'),
            trigger: 'change',
          },
        ],
        otherUrl: [
          {
            required: true,
            message: this.$t('COMMON_ERR3'),
            trigger: 'change',
          },
        ],
        country: [
          {
            required: true,
            message: this.$t('incoming_company_select_placeholder'),
            trigger: 'change',
          },
        ],
        sex: [
          {
            required: true,
            message: this.$t('incoming_company_select_placeholder'),
            trigger: 'change',
          },
        ],
        birthday: [
          {
            required: true,
            message: this.$t('incoming_company_select_placeholder'),
            trigger: 'change',
          },
        ],
        date: [
          {
            required: true,
            message: this.$t('incoming_company_select_placeholder'),
            trigger: 'change',
          },
        ],
      };
    },
  },
  methods: {
    handleRadioChange() {
      this.form.date = [];
    },
    handleUploadSuccess(path, key) {
      this.form[key] = path;
    },
    handleSuccess(response, key) {
      if (key === 'otherFileUrl') {
        this.form.otherFileUrl = response.dataSet.path;
      }
    },
    async _getArea() {
      this.countryList = await getArea();
    },
    async submitForm() {
      const submitData = { ...this.form };

      submitData.beginDate = submitData?.date?.[0];
      submitData.endDate = submitData?.date?.[1];

      delete submitData.date;

      submitData.type = 4;
      submitData.proxyPerson = this.proxyPerson;
      const res = {};
      for (const key in submitData) {
        if (submitData[key] !== '') {
          res[key] = submitData[key];
        }
      }

      return { ...res };
    },
    /**
     * 文件上传之前判断类型是否正确
     */
    beforeUpload(file) {
      const fileName = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['pdf', 'jpg', 'jpeg', 'png'];
      const isLt2M = file.size / 1024 / 1024 < 15;
      if (whiteList.indexOf(fileName) === -1) {
        this.$message.warning('只能上传 图片/pdf 文件');
        return false;
      }
      if (!isLt2M) {
        this.$message.warning('上传文件不能超过15MB');
        return false;
      }
    },
  },
  components: { Upload },
};
</script>

<style scoped lang="scss">
.container {
  width: 80%;
  margin: 0 auto;
  padding-top: 30px;

  h2 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    text-indent: 1em;
    font-weight: bold;
  }
}
::v-deep.el-upload-dragger {
  width: 270px;
}

::v-deep.el-upload__tip {
  font-size: 13px;
  margin-top: 0;
}
</style>
