<template>
  <div class="container">
    <el-card shadow="never" class="my-card">
      <div style="text-align: right; padding: 30px 50px 40px 0">
        <LanguageButton />
      </div>
      <el-steps
        :space="500"
        :active="stepActive"
        finish-status="success"
        align-center
        style="width: 1190px; margin: 30px auto"
      >
        <el-step :title="$t('incoming_step_title1')"></el-step>
        <el-step :title="$t('incoming_step_title2')"></el-step>
        <el-step :title="$t('incoming_step_title3')"></el-step>
      </el-steps>
      <template v-if="stepActive === 1">
        <div style="width: 70%; margin: 0 auto">
          <el-divider />
        </div>
        <div class="main-type">
          <span style="margin-right: 16px">{{ $t('incoming_title') }}:</span>
          <p style="margin-bottom: 0">
            <span
              :class="curArea === 0 ? 'active' : ''"
              @click="handleTabChange(0)"
            >
              {{ $t('incoming_title_type1') }}
            </span>
            <span
              :class="curArea === 1 ? 'active' : ''"
              @click="handleTabChange(1)"
            >
              {{ $t('incoming_title_type2') }}
            </span>
            <span
              :class="curArea === 2 ? 'active' : ''"
              @click="handleTabChange(2)"
            >
              {{ $t('incoming_title_type3') }}
            </span>
          </p>
        </div>
        <p class="tip">
          {{ tip }}
        </p>
      </template>
    </el-card>
    <template v-if="stepActive === 1">
      <CompanyInfo
        style="margin-bottom: 20px"
        :cur-area="curArea"
        ref="companyInfo"
        :detail="companyInfo"
      />
      <DirectorInfo
        :curArea="curArea"
        ref="directorInfo"
        style="margin-bottom: 20px"
        :detail="directorDetail"
      />
      <BeneficiaryInfo
        ref="same"
        :curArea="curArea"
        style="margin-bottom: 20px"
        :detail="beneficiaryInfoDetail"
      />
      <AgentInfo :curArea="curArea" ref="agent" style="margin-bottom: 20px" />
      <el-card shadow="never">
        <div style="text-align: center; padding: 30px 0 0 0">
          <el-button
            type="primary"
            style="margin-right: 30px"
            plain
            @click="handleSubmit(2)"
            :loading="submit_loading"
          >
            {{ $t('incoming_button1') }}
          </el-button>
          <el-button
            type="primary"
            style="width: 130px"
            @click="handleSubmit(1)"
            :loading="submit_loading"
          >
            {{ $t('incoming_button2') }}
          </el-button>
        </div>
      </el-card>
    </template>
    <template v-else-if="stepActive === 2">
      <el-card shadow="never">
        <div class="info-wrapper">
          <img src="./wait.svg" alt="等待" />
          <h3>{{ $t('incoming_message1') }}...</h3>
          <p>
            {{ $t('incoming_message2') }}
          </p>
        </div>
      </el-card>
    </template>
    <template v-else>
      <el-card shadow="never" v-show="status === 3">
        <div class="info-wrapper">
          <img src="./pass.svg" alt="通过" />
          <h3>{{ $t('incoming_message3') }}！</h3>
          <el-button size="small" @click="$router.push('/sign-agreement')">
            {{ $t('incoming_message4') }}
          </el-button>
        </div>
      </el-card>
      <el-card shadow="never" v-show="status === 2">
        <div class="info-wrapper">
          <img src="./nopass.svg" alt="通过" />
          <h3>{{ $t('incoming_message5') }}</h3>
          <div style="margin-bottom: 17px">
            <h5>{{ $t('incoming_message6') }}</h5>
          </div>
          <el-button type="primary" size="small" @click="goEditPage">
            {{ $t('incoming_message7') }}
          </el-button>
        </div>
      </el-card>
    </template>
  </div>
</template>

<script>
import CompanyInfo from './components/company-info';
import DirectorInfo from './components/director-info';
import BeneficiaryInfo from './components/beneficiary-info';
import AgentInfo from './components/agent-info';
import { getDetail, getCommitIncoming, getTempSave } from './api';
import LanguageButton from '@/components/LanguageButton.vue';

export default {
  data() {
    return {
      curArea: 0,
      stepActive: 1,
      detail: {},
      status: '',
      id: '',
      directorDetail: {},
      companyInfo: {},
      submit_loading: false,
    };
  },
  mounted() {
    this._getDetail();
  },
  methods: {
    /**
     * @param type 1: 提交 2：暂存
     * @returns {Promise<void>}
     * 暂存不需要校验
     */
    async handleSubmit(type) {
      const companyInfo = await this.$refs.companyInfo.submitForm(type);
      const directorInfo = await this.$refs.directorInfo.submitForm(type);
      const sameRes = await this.$refs.same.submitForm(type);
      const agent = await this.$refs.agent.submitForm();

      const submitData = {
        companyInfo,
        contactList: [directorInfo],
        same: sameRes.same,
      };

      if (this.id) {
        submitData.companyInfo.id = this.id;
      } else {
        submitData.companyInfo.id = '';
      }

      if (!sameRes.same) {
        sameRes.data.forEach(item => {
          submitData.contactList.push(item);
        });
      }
      if (Object.keys(agent).length) {
        submitData.contactList.push(agent);
      }
      this.submit_loading = true;
      const data =
        type === 1
          ? await getCommitIncoming(submitData)
          : await getTempSave(submitData);

      if (data) {
        if (type === 1) {
          this.stepActive = 2;
          this.$message.success('提交成功');
        } else if (type === 2) {
          this.$message.success('暂存成功');
        }
      }
      this.submit_loading = false;
    },
    async _getDetail() {
      const data = await getDetail();
      if (data.status != null) {
        const { status, companyInfo, contactList } = data;
        this.status = status;
        this.id = companyInfo.id;
        // status: 0 已提交 1未提交 2 已拒绝 3 已通过
        if (status === 0) {
          this.stepActive = 2;
        } else if (status === 1) {
          this.stepActive = 1;
          this.curArea = companyInfo.objType;
        } else if (status === 2) {
          this.status = 2;
          this.stepActive = 3;
        } else if (status === 3) {
          this.status = 3;
          this.stepActive = 3;
        }
        if (this.curArea === 0) {
          this.directorDetail = contactList.filter(item => item.type === 1)[0];
        }
        if (this.curArea !== 0) {
          this.directorDetail = contactList.filter(item => item.type === 2)[0];
        }
        this.detail = data;
        this.companyInfo = this.detail.companyInfo;
      }
    },
    goEditPage() {
      this.stepActive = 1;
    },
    // tab 更改
    handleTabChange(tab) {
      this.curArea = tab;
      this.companyInfo = { ...this.companyInfo };
      this.directorDetail = { ...this.directorDetail };
    },
  },
  components: {
    CompanyInfo,
    DirectorInfo,
    BeneficiaryInfo,
    AgentInfo,
    LanguageButton,
  },
  computed: {
    beneficiaryInfoDetail() {
      if (Object.keys(this.detail).length) {
        const { same, contactList } = this.detail;
        return {
          same,
          // data: contactList.filter(item => item.type === 3),
          data: contactList,
        };
      } else {
        return {};
      }
    },
    tip() {
      const language = this.$i18n.locale();
      const resultArr = [
        {
          cn: ['中国大陆企业', '企业离岸账户'],
          en: ['Mainland Chinese companies', 'Corporate offshore accounts'],
        },
        {
          cn: ['中国香港企业', '企业同名账户'],
          en: [
            'Enterprises in Hong Kong, China',
            'Enterprise Same Name Account',
          ],
        },
        {
          cn: ['其他地区企业', '企业同名账户'],
          en: ['Enterprises in other regions', 'Enterprise Same Name Account'],
        },
      ];
      let message = '';
      if (language === 'cn') {
        message = `您选择的注册主体类型为${
          resultArr[this.curArea][language][0]
        }，换汇后只可提现至${resultArr[this.curArea][language][1]}`;
      }

      if (language === 'en') {
        message = `The registration subject type you select is ${
          resultArr[this.curArea][language][0]
        } and cash can only be withdrawn to the ${
          resultArr[this.curArea][language][1]
        } after exchange `;
      }
      return message;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  box-sizing: border-box;
  padding: 24px;
  background: #f0f2f5;

  .my-card {
    margin-bottom: 20px;

    .main-type {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      & > div:first-child {
        width: 100%;
        height: 100%;
        display: flex;
      }

      & > p {
        span {
          display: inline-block;
          padding: 0 20px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          border: 1px solid rgba(0, 0, 0, 0.65);
          border-radius: 2px;
          margin-right: 32px;
          cursor: pointer;
        }

        span.active {
          color: white;
          border: none;
          background-color: #1890ff;
        }
      }
    }
  }

  .footer {
    text-align: center;

    .text {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }

  .tip {
    text-align: center;
    margin: 10px auto 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
    line-height: 16px;
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;

    img {
      margin: 30px 0 50px;
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    p {
      font-size: 14px;
      color: #5d5a5a;
    }
  }
}

::v-deep .el-card__body {
  width: 100%;
  height: 100%;
  padding: 0 0 24px 0;
}
</style>
