<template>
  <el-card shadow="never">
    <div class="container">
      <h2>
        {{
          curArea === 0
            ? this.$t('incoming_director_title1')
            : this.$t('incoming_director_title2')
        }}
        {{ this.$t('incoming_director_title_end') }}
      </h2>
      <el-divider />
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        label-position="top"
        style="width: 550px; margin: 0 auto"
      >
        <el-form-item
          :label="$t('incoming_director_form1')"
          prop="identityType"
        >
          <el-radio-group v-model="form.identityType">
            <el-radio :label="1">{{ $t('incoming_director_form2') }}</el-radio>
            <el-radio :label="2">{{ $t('incoming_director_form3') }}</el-radio>
            <el-radio :label="3" v-if="curArea !== 0">
              {{ $t('incoming_director_form4') }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 证件类型为 身份证 -->
        <template v-if="form.identityType === 1">
          <el-form-item
            :label="
              curArea === 0
                ? $t('incoming_director_form5')
                : $t('incoming_director_form6')
            "
            prop="identityUrl"
          >
            <div style="display: flex">
              <upload
                :content-writing="$t('incoming_director_form7')"
                :serverImageUrl="form.identityUrl"
                @upload-success="
                  path => handleUploadSuccess(path, 'identityUrl')
                "
                style="margin-right: 20px"
              />
              <upload
                :content-writing="$t('incoming_director_form8')"
                :serverImageUrl="form.identityBackUrl"
                @upload-success="
                  path => handleUploadSuccess(path, 'identityBackUrl')
                "
              />
            </div>
          </el-form-item>
        </template>
        <!-- 证件类型为 护照 -->
        <template v-if="form.identityType === 2">
          <el-form-item :label="$t('incoming_director_form9')" prop="country">
            <el-select
              v-model="form.country"
              :placeholder="$t('incoming_company_select_placeholder')"
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="item in countryList"
                :label="item.name"
                :value="item.code"
                :key="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="
              curArea === 1
                ? $t('incoming_director_form5')
                : $t('incoming_director_form6')
            "
            prop="otherUrl"
          >
            <upload
              class="upload"
              :contentWriting="$t('incoming_director_form10')"
              :serverImageUrl="form.licenseUrl"
              @upload-success="response => handleSuccess(response, 'otherUrl')"
            />
            <div class="el-upload__tip">
              {{ $t('incoming_company_upload_tip3') }}
            </div>
          </el-form-item>
        </template>
        <!-- 证件类型为 香港永久性居民身份证 -->
        <template v-if="form.identityType === 3">
          <el-form-item :label="$t('incoming_director_form6')" prop="otherUrl">
            <upload
              class="upload"
              :contentWriting="$t('incoming_director_form10')"
              :serverImageUrl="form.licenseUrl"
              @upload-success="response => handleSuccess(response, 'otherUrl')"
            />
            <div class="el-upload__tip">
              {{ $t('incoming_company_upload_tip3') }}
            </div>
            <!--<el-upload
              drag
              :action="this.fileUrl"
              :headers="uploadHeader"
              :limit="1"
              :on-success="response => handleSuccess(response, 'otherUrl')"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">点击上传护照首页图片</div>
              <div class="el-upload__tip" slot="tip">
                请确认信息无误，上传证件图片清晰可见，图片支持jpg、png、jpeg，文件大小不超过5MB
              </div>
            </el-upload>-->
          </el-form-item>
        </template>
        <el-form-item
          :label="
            curArea === 0
              ? $t('incoming_director_form11')
              : $t('incoming_director_form12')
          "
          prop="name"
        >
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item
          :label="
            curArea === 0
              ? $t('incoming_director_form13')
              : $t('incoming_director_form14')
          "
          prop="engName"
        >
          <el-input v-model="form.engName" />
        </el-form-item>
        <el-form-item
          :label="$t('incoming_director_form15')"
          prop="identityNumber"
        >
          <el-input v-model="form.identityNumber" />
        </el-form-item>
        <el-form-item :label="$t('incoming_director_form16')" prop="sex">
          <el-select
            v-model="form.sex"
            :placeholder="$t('incoming_company_select_placeholder')"
            filterable
            style="width: 100%"
          >
            <el-option :label="$t('incoming_director_form17')" :value="0" />
            <el-option :label="$t('incoming_director_form18')" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('incoming_director_form19')" prop="birthday">
          <el-date-picker
            v-model="form.birthday"
            type="date"
            :placeholder="$t('incoming_company_select_placeholder')"
            style="width: 100%"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item
          :label="$t('incoming_director_form20')"
          :prop="form.longTerm ? '' : 'date'"
        >
          <el-date-picker
            v-model="form.date"
            type="daterange"
            :range-separator="$t('incoming_company_date_placeholder1')"
            :start-placeholder="$t('incoming_company_date_placeholder2')"
            :end-placeholder="$t('incoming_company_date_placeholder3')"
            style="width: 100%"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :disabled="form.longTerm"
          />
          <el-radio-group v-model="form.longTerm" @change="handleRadioChange">
            <el-radio :label="false">
              {{ $t('incoming_director_form21') }}
            </el-radio>
            <el-radio :label="true">
              {{ $t('incoming_director_form22') }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="
            curArea === 0
              ? $t('incoming_director_form23')
              : $t('incoming_director_form24')
          "
          prop="address"
        >
          <el-input v-model="form.address" />
        </el-form-item>
        <el-form-item
          :label="$t('incoming_director_form25')"
          prop="addressProfileUrl"
        >
          <upload
            :content-writing="$t('incoming_director_form25')"
            :serverImageUrl="form.addressProfileUrl"
            @upload-success="
              path => handleUploadSuccess(path, 'addressProfileUrl')
            "
          />
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>

<script>
import { getArea } from '../api';
import storage from '@/utils/storage';
import Upload from './upload';
import config from '@/config';
import { commonCheck } from '@/utils/utils';

export default {
  props: {
    // 0 大陆  1 香港  2 其他企业
    curArea: {
      type: Number,
      default: 1,
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    curArea: {
      handler() {
        if (this.$refs.form) {
          this.$refs.form.resetFields();
          Object.keys(this.form).forEach(key => (this.form[key] = ''));
        }
      },
    },
    detail: {
      handler(newVal) {
        if (newVal && Object.keys(newVal).length) {
          Object.keys(this.form).forEach(key => {
            this.form[key] = newVal[key];
          });
          const { beginDate, endDate } = newVal;
          if (beginDate && endDate) {
            this.form.date = [beginDate, endDate];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.fileUrl = `${config.baseApi}/file-api/file-server/saas/add`;
  },
  mounted() {
    this._getArea();
  },
  data() {
    return {
      form: {
        identityType: 1,
        identityUrl: '',
        identityBackUrl: '',
        otherUrl: '',
        addressProfileUrl: '',
        country: '',
        name: '',
        engName: '',
        identityNumber: '',
        sex: '',
        birthday: '',
        longTerm: false,
        address: '',
        date: '',
      },
      countryList: [],
      uploadHeader: { Authorization: storage.getItem('token') },
    };
  },
  computed: {
    rules() {
      return {
        name: [
          {
            validator: commonCheck,
            required: true,
            trigger: 'blur',
          },
        ],
        engName: [
          {
            validator: commonCheck,
            required: true,
            trigger: 'blur',
          },
        ],
        address: [
          {
            validator: commonCheck,
            required: true,
            trigger: 'blur',
          },
        ],
        identityNumber: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        identityUrl: [
          {
            required: true,
            message: this.$t('COMMON_ERR3'),
            trigger: 'change',
          },
        ],
        addressProfileUrl: [
          {
            required: true,
            message: this.$t('COMMON_ERR3'),
            trigger: 'change',
          },
        ],
        otherUrl: [
          {
            required: true,
            message: this.$t('COMMON_ERR3'),
            trigger: 'change',
          },
        ],
        country: [
          {
            required: true,
            message: this.$t('incoming_company_select_placeholder'),
            trigger: 'change',
          },
        ],
        sex: [
          {
            required: true,
            message: this.$t('incoming_company_select_placeholder'),
            trigger: 'change',
          },
        ],
        birthday: [
          {
            required: true,
            message: this.$t('incoming_company_select_placeholder'),
            trigger: 'change',
          },
        ],
        date: [
          {
            required: true,
            message: this.$t('incoming_company_select_placeholder'),
            trigger: 'change',
          },
        ],
      };
    },
  },
  methods: {
    handleRadioChange() {
      this.form.date = [];
      this.$refs.form.clearValidate();
    },
    handleUploadSuccess(path, key) {
      this.form[key] = path;
    },
    async _getArea() {
      this.countryList = await getArea();
    },
    async submitForm(type) {
      if (type === 2) {
        const submitData = { ...this.form };
        submitData.beginDate = submitData?.date?.[0];
        submitData.endDate = submitData?.date?.[1];
        delete submitData.date;
        if (this.curArea !== 0) {
          submitData.type = 2;
        } else {
          submitData.type = 1;
        }
        return { ...submitData };
      }
      const valid = await this.$refs.form.validate();
      if (valid) {
        const submitData = { ...this.form };

        submitData.beginDate = submitData?.date?.[0];
        submitData.endDate = submitData?.date?.[1];

        delete submitData.date;

        if (this.curArea !== 0) {
          submitData.type = 2;
        } else {
          submitData.type = 1;
        }
        const res = {};
        for (const key in submitData) {
          if (submitData[key] !== '') {
            res[key] = submitData[key];
          }
        }

        return { ...res };
      } else {
        return null;
      }
    },
    handleSuccess(response, key) {
      if (key === 'otherUrl') {
        this.form.otherUrl =
          typeof response === 'string' ? response : response.dataSet.path;
      }
      if (key === 'addressProfileUrl') {
        this.form.addressProfileUrl = response.dataSet.path;
      }
    },
  },
  components: { Upload },
};
</script>

<style scoped lang="scss">
.container {
  width: 80%;
  margin: 0 auto;
  padding-top: 30px;

  h2 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    text-indent: 1em;
    font-weight: bold;
  }
}
::v-deep.el-upload-dragger {
  width: 270px;
}

::v-deep.el-upload__tip {
  font-size: 13px;
  margin-top: 0;
}
</style>
