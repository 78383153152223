import request from '@/utils/request';

// 提交进件
export const getCommitIncoming = params =>
  request.post('/manager-api/exchange/company/commit', params);
// 暂存进件
export const getTempSave = params =>
  request.post('/manager-api/exchange/company/save', params);
/**
 * 获取所有下拉内容
 * @param params
 * {
 *   kind: 类别,  注册地:2  经营地:3 公司性质:4 注册年限:5 注册资本:6 经营平台:7 入网方式:8 业务规模:9 商业关系:10
 *   industry: 所属行业
 * }
 * @returns {*}
 */
export const getSelectList = params =>
  request.get('/setting-api/riskScore/factor/select', { params });

// 获取所有国家
export const getArea = () => request.get('/setting-api/area/getAllCountry');

export const getDetail = () => request.get('/manager-api/exchange/company/get');

// 1币种 2产品 3业务类型 4物流 5仓库
export const getConfigList = typeId =>
  request.post('/setting-api/globalConfig/queryGlobalConfigList', { typeId });

// 获取上传图片(base64 格式)
export const getBase64 = id =>
  request.get(`/file-api/file-server/saas/show/thumb/${id}/120/120`);
