<template>
  <el-upload
    class="upload"
    :action="fileUrl"
    :headers="uploadHeader"
    :show-file-list="false"
    :on-success="handleUploadSuccess"
    :before-upload="beforeAvatarUpload"
  >
    <img v-if="showImageUrl" :src="showImageUrl" class="avatar" alt="图片" />
    <div v-else class="icon-wrap">
      <i class="el-icon-upload" />
      <p>{{ $t('incoming_company_upload1') }}{{ contentWriting }}</p>
    </div>
  </el-upload>
</template>

<script>
import storage from '@/utils/storage';
import { getBase64 } from '../api';
import { ERR_OK } from '@/utils/request';
import config from '@/config';

export default {
  props: {
    actionUrl: {
      type: String,
      default: '',
    },
    serverImageUrl: {
      type: String,
      default: '',
    },
    contentWriting: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uploadHeader: { Authorization: storage.getItem('token') },
      fileUrl: '',
      showImageUrl: '',
    };
  },
  watch: {
    serverImageUrl: {
      handler(newVal) {
        if (newVal) {
          this.getServerImageUrl(newVal);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    if (!this.actionUrl) {
      // 此接口为默认上传接口
      this.fileUrl = `${config.baseApi}/file-api/file-server/saas/add`;
    } else {
      this.fileUrl = this.actionUrl;
    }
  },
  methods: {
    async getServerImageUrl(path) {
      const arr = path.split('/');
      const id = arr[arr.length - 1];
      if (id) {
        const data = await getBase64(id);
        this.showImageUrl = `data:image/png;base64,${data}`;
      }
    },
    handleUploadSuccess({ code, dataSet }, file) {
      if (code === ERR_OK) {
        this.$emit('upload-success', dataSet.path);
        this.showImageUrl = URL.createObjectURL(file.raw);
        this.$message.success('上传成功');
      } else {
        this.$message.error('上传失败');
      }
    },
    beforeAvatarUpload(file) {
      const fileName = file.name.substring(file.name.lastIndexOf('.') + 1);
      const whiteList = ['jpg', 'jpeg', 'png'];
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (whiteList.indexOf(fileName) === -1) {
        this.$message.warning('只能上传jpg、jpeg、png文件');
        return false;
      }
      if (!isLt2M) {
        this.$message.warning('上传文件不能超过5MB');
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.upload {
  ::v-deep.el-upload {
    width: 140px;
    height: 140px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep.el-upload:hover {
    border-color: #409eff;
  }
}
.icon-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  background-color: rgba(24, 144, 255, 0.12);

  .el-icon-upload {
    font-size: 26px;
    line-height: 40px;
    color: #1890ff;
    text-align: center;
  }

  p {
    font-size: 13px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.45);
  }
}

.avatar {
  width: 140px;
  height: 140px;
  display: block;
}
</style>
