<template>
  <el-card shadow="never">
    <div class="container">
      <h2>{{ $t('incoming_beneficiary_title') }}</h2>
      <el-divider />
      <el-form
        ref="form"
        :model="formModel"
        label-width="120px"
        label-position="top"
        style="width: 550px; margin: 0 auto"
      >
        <el-form-item>
          <el-radio-group v-model="same">
            <el-radio :label="true">
              {{ $t('incoming_beneficiary_form1') }}
            </el-radio>
            <el-radio :label="false">
              {{ $t('incoming_beneficiary_form2') }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="!same">
          <div v-for="(form, index) in formModel.arr" :key="index">
            <div style="width: 550px; margin: 0 auto 20px">
              <div style="display: flex; justify-content: space-between">
                <span style="line-height: 40px; font-size: 15px">
                  {{ $t('incoming_beneficiary_form3') }}{{ index + 1 }}
                </span>
                <div style="text-align: right">
                  <el-button
                    icon="el-icon-plus"
                    type="text"
                    style="margin-right: 15px"
                    @click="handleAddItem"
                  >
                    {{ $t('incoming_beneficiary_form4') }}
                  </el-button>
                  <el-button
                    icon="el-icon-delete"
                    type="text"
                    @click="handleRemove(index)"
                  >
                    {{ $t('incoming_beneficiary_form5') }}
                  </el-button>
                </div>
              </div>
              <el-divider />
            </div>
            <el-form-item
              :label="$t('incoming_beneficiary_form6')"
              :prop="'arr.' + index + '.identityType'"
            >
              <el-radio-group v-model="form.identityType">
                <el-radio :label="1">
                  {{ $t('incoming_beneficiary_form7') }}
                </el-radio>
                <el-radio :label="2">
                  {{ $t('incoming_beneficiary_form8') }}
                </el-radio>
                <el-radio :label="3" v-if="curArea !== 0">
                  {{ $t('incoming_beneficiary_form9') }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- 证件类型为 身份证 -->
            <template v-if="form.identityType === 1">
              <el-form-item
                :label="$t('incoming_beneficiary_form10')"
                :prop="'arr.' + index + '.identityUrl'"
                :rules="{
                  required: true,
                  message: $t('incoming_beneficiary_form11'),
                  trigger: 'change',
                }"
              >
                <div style="display: flex">
                  <upload
                    :contentWriting="$t('incoming_director_form7')"
                    :serverImageUrl="form.identityUrl"
                    @upload-success="
                      path => handleUploadSuccess(path, 'identityUrl', index)
                    "
                    style="margin-right: 20px"
                  />
                  <upload
                    :contentWriting="$t('incoming_director_form8')"
                    :serverImageUrl="form.identityBackUrl"
                    @upload-success="
                      path =>
                        handleUploadSuccess(path, 'identityBackUrl', index)
                    "
                  />
                </div>
              </el-form-item>
            </template>
            <!-- 证件类型为 护照 -->
            <template v-if="form.identityType === 2">
              <el-form-item
                :label="$t('incoming_director_form9')"
                :prop="'arr.' + index + '.country'"
                :rules="{
                  required: true,
                  message: $t('COMMON_ERR4'),
                  trigger: 'change',
                }"
              >
                <el-select
                  v-model="form.country"
                  :placeholder="$t('incoming_company_select_placeholder')"
                  filterable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in countryList"
                    :label="item.name"
                    :value="item.code"
                    :key="item.code"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('incoming_beneficiary_form10')"
                :prop="'arr.' + index + '.otherUrl'"
                :rules="{
                  required: true,
                  message: $t('incoming_beneficiary_form11'),
                  trigger: 'change',
                }"
              >
                <upload
                  :content-writing="$t('incoming_director_form10')"
                  :serverImageUrl="form.otherUrl"
                  @upload-success="
                    path => handleUploadSuccess(path, 'otherUrl', index)
                  "
                />
              </el-form-item>
            </template>
            <!-- 证件类型为 香港永久性居民身份证 -->
            <template v-if="form.identityType === 3">
              <el-form-item
                :label="$t('incoming_beneficiary_form10')"
                :prop="'arr.' + index + '.otherUrl'"
                :rules="{
                  required: true,
                  message: $t('incoming_beneficiary_form11'),
                  trigger: 'change',
                }"
              >
                <upload
                  :content-writing="$t('incoming_beneficiary_form12')"
                  :serverImageUrl="form.otherUrl"
                  @upload-success="
                    path => handleUploadSuccess(path, 'otherUrl', index)
                  "
                />
              </el-form-item>
            </template>
            <el-form-item
              :label="$t('incoming_beneficiary_form13')"
              :prop="'arr.' + index + '.name'"
              :rules="{
                validator: commonCheck,
                required: true,
                trigger: 'blur',
              }"
            >
              <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item
              :label="$t('incoming_beneficiary_form14')"
              :prop="'arr.' + index + '.engName'"
              :rules="{
                validator: commonCheck,
                required: true,
                trigger: 'blur',
              }"
            >
              <el-input v-model="form.engName" />
            </el-form-item>
            <el-form-item
              :label="$t('incoming_director_form15')"
              :prop="'arr.' + index + '.identityNumber'"
              :rules="{
                required: true,
                message: $t('COMMON_ERR1'),
                trigger: 'blur',
              }"
            >
              <el-input v-model="form.identityNumber" />
            </el-form-item>
            <el-form-item
              :label="$t('incoming_company_form20')"
              :prop="'arr.' + index + '.date'"
              :rules="{
                required: true,
                message: $t('incoming_company_select_placeholder'),
                trigger: 'change',
              }"
            >
              <el-date-picker
                v-model="form['date']"
                type="daterange"
                :range-separator="$t('incoming_company_date_placeholder1')"
                :start-placeholder="$t('incoming_company_date_placeholder2')"
                :end-placeholder="$t('incoming_company_date_placeholder3')"
                style="width: 100%"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
            <el-form-item
              :label="$t('incoming_director_form16')"
              :prop="'arr.' + index + '.sex'"
              :rules="{
                required: true,
                message: $t('COMMON_ERR1'),
                trigger: 'blur',
              }"
            >
              <el-select
                v-model="form.sex"
                :placeholder="$t('incoming_company_select_placeholder')"
                filterable
                style="width: 100%"
              >
                <el-option :label="$t('incoming_director_form17')" :value="0" />
                <el-option :label="$t('incoming_director_form18')" :value="1" />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('incoming_director_form19')"
              :prop="'arr.' + index + '.birthday'"
              :rules="{
                required: true,
                message: $t('incoming_company_select_placeholder'),
                trigger: 'change',
              }"
            >
              <el-date-picker
                v-model="form.birthday"
                type="date"
                :placeholder="$t('incoming_company_select_placeholder')"
                style="width: 100%"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
            <el-form-item
              :label="$t('incoming_director_form24')"
              :prop="'arr.' + index + '.address'"
              :rules="{
                validator: commonCheck,
                required: true,
                trigger: 'blur',
              }"
            >
              <el-input v-model="form.address" />
            </el-form-item>
            <el-form-item :label="$t('incoming_director_form25')" required>
              <upload
                :contentWriting="$t('incoming_director_form25')"
                :serverImageUrl="form.addressProfileUrl"
                @upload-success="
                  path => handleUploadSuccess(path, 'addressProfileUrl', index)
                "
              />
            </el-form-item>
          </div>
        </template>
      </el-form>
    </div>
  </el-card>
</template>

<script>
import storage from '@/utils/storage';
import { getArea } from 'views/incoming-unit/api';
import Upload from './upload';
import _ from 'lodash';
import { commonCheck } from '@/utils/utils';

const formItem = {
  identityType: 1,
  identityUrl: '',
  identityBackUrl: '',
  otherUrl: '',
  country: '',
  name: '',
  engName: '',
  identityNumber: '',
  sex: '',
  birthday: '',
  longTerm: false,
  address: '',
  date: ['', ''],
};

export default {
  props: {
    // 0 大陆  1 香港  2 其他企业
    curArea: {
      type: Number,
      default: 0,
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    curArea: {
      handler() {
        if (this.$refs.form) {
          this.$refs.form.resetFields();
          this.formModel.arr.forEach(obj => {
            Object.keys(obj).forEach(key => {
              obj[key] = '';
            });
          });
        }
      },
    },
    detail: {
      handler(newVal) {
        if (Object.keys(newVal).length) {
          const { same, data } = newVal;
          this.same = same;

          this.formModel.arr = _.cloneDeep(data);
          if (this.formModel.arr.length === 0) {
            this.handleAddItem();
          }
        }
      },
      deep: true,
    },
  },

  mounted() {
    this._getArea();
    this.formModel.arr.push(_.cloneDeep(formItem));
  },
  data() {
    return {
      same: true,
      formModel: {
        arr: [],
      },
      uploadHeader: { Authorization: storage.getItem('token') },
      countryList: [],
      commonCheck,
    };
  },
  methods: {
    async submitForm(type) {
      if (type === 2) {
        const submitData = _.cloneDeep(this.formModel.arr);

        submitData.map(obj => {
          obj.type = 3;
          obj['beginDate'] = obj['date'] && obj?.['date']?.[0];
          obj['endDate'] = obj['date'] && obj?.['date']?.[1];
          delete obj['date'];
          Object.keys(obj).forEach(key => {
            if (obj[key] === '') {
              delete obj[key];
            }
          });
        });
        return {
          same: this.same,
          data: submitData,
        };
      } else {
        const valid = await this.$refs.form.validate();

        if (valid) {
          const submitData = _.cloneDeep(this.formModel.arr);
          submitData.map(obj => {
            obj.type = 3;
            obj['beginDate'] = obj?.date?.[0];
            obj['endDate'] = obj?.['date']?.[1];
            delete obj['date'];
            Object.keys(obj).forEach(key => {
              if (obj[key] === '') {
                delete obj[key];
              }
            });
          });
          return {
            same: this.same,
            data: submitData,
          };
        }
      }
    },
    async _getArea() {
      this.countryList = await getArea();
    },
    handleUploadSuccess(path, key, index) {
      this.formModel.arr[index][key] = path;
    },
    handleRemove(index) {
      if (this.formModel.arr.length === 1) {
        return this.$message.warning(this.$t('incoming_beneficiary_form15'));
      }
      this.formModel.arr.splice(index, 1);
    },
    handleAddItem() {
      if ((this, this.formModel.arr.length === 10)) {
        return this.$message.warning(this.$t('incoming_beneficiary_form16'));
      }
      this.formModel.arr.push(_.cloneDeep(formItem));
    },
  },
  components: { Upload },
};
</script>

<style scoped lang="scss">
.container {
  width: 80%;
  margin: 0 auto;
  padding-top: 30px;

  h2 {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    text-indent: 1em;
    font-weight: bold;
  }
}
::v-deep.el-upload-dragger {
  width: 270px;
}

::v-deep.el-upload__tip {
  font-size: 13px;
  margin-top: 0;
}
::v-deep.el-divider {
  margin: 10px 0;
}
</style>
